import { Component, Inject, Optional, PLATFORM_ID, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { CookieService } from './services/cookie-service.service';
import { DomSanitizer, TransferState } from '@angular/platform-browser';
import { NavbarTheme } from './modules/shared/navbar/theme';
import { Router } from '@angular/router';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import { JobUrl } from './modules/job/job.url';
import { TalentUrl } from './modules/talent/talent.url';
import { CookiesUrl } from './modules/cookies/cookies.url';
import { LegalUrl } from './modules/legal/legal.url';
import { BlogUrl } from './modules/blog/blog.url';
import { JobOffertsUrl } from './modules/jobOfferts/jobOfferts.url';
import { HttpClient } from '@angular/common/http';
import { KPI } from './interfaces/kpi';
import { KPIS_VALUE } from '../tokens';
import { REQUEST, SsrCookieService } from 'ngx-cookie-service-ssr';
import { Request } from 'express';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.sass',
	animations: [
		trigger('animateDestroy', [state('void', style({ opacity: '0' })), transition('* => void', animate('500ms ease'))]),
	],
})
export class AppComponent {
	mandatoryCookiesConsent$?: Observable<boolean>;
	thirdPartyCookiesConsent$?: Observable<boolean>;
	mandatoryCookiesConsent: boolean = true;
	thirdPartyCookiesConsent: boolean = false;

	readonly LIGHT_THEME_VIEWS = [`/${JobUrl.Base}`];
	readonly FOOTER_BACKGROUND_COLORS = new Map<string, string>([
		[`/${JobUrl.Base}`, 'bg-MoradoClaro'],
		[`/${TalentUrl.Base}`, 'bg-AzulOscuro'],
		[`/${CookiesUrl.Base}`, 'bg-AzulOscuro'],
		[`/${LegalUrl.Base}`, 'bg-AzulOscuro'],
		[`/${BlogUrl.Base}`, 'bg-MoradoClaro'],
		[`/${BlogUrl.BasePost}`, 'bg-MoradoClaro'],
		[`/${JobOffertsUrl.Base}`, 'bg-MoradoClaro'],
	]);
	readonly defaultFooterColorClass = 'bg-AzulOscuro';

	constructor(
		public translate: TranslateService,
		@Inject(DOCUMENT) private document: Document,
		public cookieService: CookieService,
		private sanitizer: DomSanitizer,
		private _renderer2: Renderer2,
		public router: Router,
		@Inject(PLATFORM_ID) private platformId: object,
		private http: HttpClient,
		private transferState: TransferState,
		private ssrCookies: SsrCookieService,
		@Optional() @Inject(REQUEST) private req: Request
	) {
		// SSR here data for more than one view
		if (isPlatformServer(this.platformId)) {
			this.http.get<KPI>('/api/kpis').subscribe((response) => {
				this.transferState.set(KPIS_VALUE, response);
			});
		}

		// SSR Language Checker
		const cookieLang = this.cookieService.getLanguage();
		const userLanguage = cookieLang !== '' ? cookieLang : 'es';

		if (isPlatformBrowser(platformId)) {
			this.translate.addLangs(['en', 'es', 'pt', 'br', 'it', 'fr', 'de']);

			this.mandatoryCookiesConsent$ = this.cookieService.getMandatoryCookiesConsent();
			this.thirdPartyCookiesConsent$ = this.cookieService.getThirdPartyCookiesConsent();

			this.mandatoryCookiesConsent$.subscribe((val) => {
				this.mandatoryCookiesConsent = val;
			});

			this.thirdPartyCookiesConsent$.subscribe((val) => {
				this.thirdPartyCookiesConsent = val;
				if (!this.thirdPartyCookiesConsent) {
					this.cookieService.deleteThirdPartyCookies();
				}
			});
		}
		this.translate.use(userLanguage.toLowerCase());
	}

	getGTMUrl() {
		return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.googletagmanager.com/ns.html?id=GTM-P6JLMSF');
	}

	getCurrentTheme(): NavbarTheme {
		return this.isLightView(this.router.url) ? NavbarTheme.LIGHT : NavbarTheme.DARK;
	}

	getFooterBackgroundColorClass(): string {
		for (const [key, value] of this.FOOTER_BACKGROUND_COLORS) {
			if (this.router.url.includes(key)) {
				return value;
			}
		}

		return this.defaultFooterColorClass;
	}

	isLightView(view: string): boolean {
		for (const v of this.LIGHT_THEME_VIEWS) {
			if (view.includes(v)) return true;
		}

		return false;
	}
}
