import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeUrl } from './modules/home/home.url';
import { JobUrl } from './modules/job/job.url';
import { TalentUrl } from './modules/talent/talent.url';
import { LegalUrl } from './modules/legal/legal.url';
import { CookiesUrl } from './modules/cookies/cookies.url';
import { BlogUrl } from './modules/blog/blog.url';
import { PostUrl } from './modules/post/post.url';
import { JobOffertsUrl } from './modules/jobOfferts/jobOfferts.url';

const routes: Routes = [
	{
		path: HomeUrl.Base,
		loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
	},
	{
		path: JobUrl.Base,
		loadChildren: () => import('./modules/job/job.module').then((m) => m.JobModule),
	},
	{
		path: TalentUrl.Base,
		loadChildren: () => import('./modules/talent/talent.module').then((m) => m.TalentModule),
	},
	{
		path: LegalUrl.Base,
		loadChildren: () => import('./modules/legal/legal.module').then((m) => m.LegalModule),
	},
	{
		path: CookiesUrl.Base,
		loadChildren: () => import('./modules/cookies/cookies.module').then((m) => m.CookiesModule),
	},
	{
		path: BlogUrl.Base,
		loadChildren: () => import('./modules/blog/blog.module').then((m) => m.BlogModule),
	},
	{
		path: PostUrl.Base,
		loadChildren: () => import('./modules/post/post.module').then((m) => m.PostModule),
	},
	{
		path: JobOffertsUrl.Base,
		loadChildren: () => import('./modules/jobOfferts/jobOfferts.module').then((m) => m.JobOffertsModule),
	},
	{
		path: ':title',
		loadChildren: () => import('./modules/campaign/campaign.module').then((m) => m.CampaignModule),
	},
	{ path: '**', redirectTo: HomeUrl.Base },
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			scrollPositionRestoration: 'enabled',
			anchorScrolling: 'enabled',
		}),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}
